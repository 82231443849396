.body {
  font-size: 1rem;
}

.body2 {
  font-size: 1.4rem;
}
a:hover{
  color: #e0d970;
}
.my-form-body {
  background-color: rgb(233, 233, 233);
  border-radius: 0.7rem;
  padding: 2rem 1rem;
}

.my-input {
  border: none;
  border-radius: 0.4rem;
  outline: none;
  padding: 0rem 0.5rem;
  color: #0071c2;
}

.my-service-image {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.my-title{
  font-size:  2.2rem;
  line-height:  1.2;
  font-weight: 400;
}

.my-light-heading{
  font-size:  1.4rem;
  line-height:  1.2;
  font-weight: 300;
}

@media (min-width: 540px) {
  .my-service-image {
    width: 100%;
    object-fit: cover;
    height: 50vh;
  }

  .my-title{
    font-size:  3.5rem;
    line-height:  1.2;
    font-weight: 400;
  }

  .my-light-heading{
    font-size:  2rem;
    line-height:  1.2;
    font-weight: 300;
  }
  
}
@media (min-width: 720px) {
  .my-service-image {
    width: 100%;
    object-fit: cover;
    height: 50vh;
  }
}
@media (min-width: 960px) {
  .my-service-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}
@media (min-width: 1140px) {
  .my-service-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.link-white{
  color: white;
}
