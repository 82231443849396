.my-wa-button{
    border-radius: 50rem;
    color: white;
    padding: .5rem .8rem;
    outline: none;
    border: none;
    font-size: 1rem;
    width: auto;
    background-color: #25D366;
    position: fixed;
    z-index: 200;
    right: 1rem;
    bottom: 1rem;
    box-shadow: .1rem .14rem .4rem .04rem #205c367e;
}
.my-wa-button:hover{
    box-shadow: .1rem .16rem .4rem .1rem #265f3b7e;
    transition: all;

}
.my-wa-button:active{
    transform: translateY(.05rem);
    box-shadow:none;
    background-color: #21b858;

}

.my-wa-button-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
}
